<template>
    <Calendar />
</template>

<script>
import Calendar from '@apps/Calendar/components/NavCalendar/index.vue'
export default {
    components: {
        Calendar
    }
}
</script>